<template>
  <div id="home">
    <div class="section" id="header">
      <md-avatar id="dynchan-logo">
        <img src="@/assets/dynchan.png" alt="Top.gg" />
      </md-avatar>
      <h1 id="titleHeader">DynChan</h1>
      <div id="buttonsContainer">
        <md-button md-theme="default" class="headerButtons" target="_blank" :href="URLS.BOT_INVITE"> Invite DynChan Now </md-button>
        <md-button md-theme="default" class="headerButtons" target="_blank" :href="URLS.DISCORD_INVITE"> Join Our Discord </md-button>
      </div>
      <div id="descriptionContainer">
        <!-- <md-divider md-theme="default"></md-divider> -->
        <p class="description">Fully <span class="dynchanMark">dyn</span>amic voice & text <span class="dynchanMark">chan</span>nels!</p>
        <md-divider md-theme="default"></md-divider>
        <p class="description">Highly customizable with web UI!</p>
        <md-divider md-theme="default"></md-divider>
        <p class="description">Including beta channel!</p>
        <md-divider md-theme="default"></md-divider>
        <p class="description">100% free without paywalls or premium features, forever!</p>
      </div>
    </div>
    <div class="section" id="features">
      <p class="description">...</p>
      <p class="description">Features previews are coming soon!</p>
    </div>
    <!-- <div class="section" :class="{ triggered: triggeredBackground }" id="plans">
      <h1 id="titlePlans">Buy DynChan+ now!</h1>
      <div v-if="triggeredText" class="justkidding" id="justkidding">
        <span class="justkidding">Just kidding!</span>
        <span class="justkidding">No paywalls.</span>
        <span class="justkidding">No premium features.</span>
        <span class="justkidding">DynChan is and will forever be free!</span>
        <span class="justkidding" id="donation">(donations are appreciated though)</span>
      </div>
      <div id="planContainer">
        <div class="plan">
          <p class="planText planMonthly">Monthly</p>
          <p class="planText planPriceCurrency">$</p>
          <p class="planPrice">3.99</p>
          <p class="planText planName">DynChan+</p>
          <md-divider md-theme="default"></md-divider>
          <p class="planText greyBold otherPayments">Other payment plans:</p>
          <p class="planText greyBold">$39.99<span class="noBold">/anually</span></p>
          <p class="planText greyBold">$69.99<span class="noBold">/lifetime</span></p>
          <p class="planText noBold">includes <span class="bold">1</span> server</p>
          <md-button class="planBuyButton" @click="buynow()">Buy Now</md-button>
        </div>
        <div class="plan">
          <p class="planText planMonthly">Monthly</p>
          <p class="planText planPriceCurrency">$</p>
          <p class="planPrice">7.99</p>
          <p class="planText planName">DynChan++</p>
          <md-divider md-theme="default"></md-divider>
          <p class="planText greyBold otherPayments">Other payment plans:</p>
          <p class="planText greyBold">$69.99<span class="noBold">/anually</span></p>
          <p class="planText greyBold">$99.99<span class="noBold">/lifetime</span></p>
          <p class="planText noBold">includes <span class="bold">3</span> server</p>
          <md-button class="planBuyButton" @click="buynow()">Buy Now</md-button>
        </div>
        <div class="plan">
          <p class="planText planMonthly">Monthly</p>
          <p class="planText planPriceCurrency">$</p>
          <p class="planPrice">15.99</p>
          <p class="planText planName">DynChan+++</p>
          <md-divider md-theme="default"></md-divider>
          <p class="planText greyBold otherPayments">Other payment plans:</p>
          <p class="planText greyBold">$129.99<span class="noBold">/anually</span></p>
          <p class="planText greyBold">$199.99<span class="noBold">/lifetime</span></p>
          <p class="planText noBold">includes <span class="bold">10</span> server</p>
          <md-button class="planBuyButton" @click="buynow()">Buy Now</md-button>
        </div>
      </div>
    </div> -->
    <div class="section" id="footer">
      <p id="byzlyfer">made with ♥ and lots of energy drinks by zlyfer</p>
    </div>
  </div>
</template>

<script>
  const CONSTANTS = require("@/constants.js");

  export default {
    name: "home",
    components: {},
    data() {
      return {
        URLS: CONSTANTS.URLS,
        triggeredBackground: false,
        triggeredText: false,
      };
    },
    methods: {
      buynow() {
        this.triggeredBackground = true;
        setTimeout(() => {
          this.triggeredText = true;
        }, 2000);
      },
    },
  };
</script>

<style lang="scss" scoped>
  * {
    font-family: Poppins;
  }
  *::-webkit-scrollbar-track {
    background-color: #e1e1e1;
  }
  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 0 !important;
    background-color: #448aff !important;
  }
  div#home {
    height: 100vh;
    background-color: #448aff;
  }
  div.section {
    width: 100%;
    margin: 0 !important;
    float: left;
    position: relative;
  }
  /* ------------------------------------------------------ */
  /*                         HEADER                         */
  /* ------------------------------------------------------ */
  div#header {
    background-color: #448aff;
  }
  .md-avatar {
    margin: 0 auto;
    width: 100px;
    height: auto;
    margin-left: calc(50% - 50px);
    margin-top: 20px;
    border-radius: 50% !important;
    border: 2px solid #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
  h1#titleHeader {
    color: #fff;
    font-size: 4em;
    text-align: center;
    font-weight: bold;
  }
  div#buttonsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    margin-bottom: 20px;
  }
  .md-button.headerButtons {
    height: 50px;
    width: 200px;
    color: #03a8f4;
    font-weight: bold;
    font-size: 1.1em;
    border-radius: 20px;
    // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    margin: 0 10px;
    background-color: white !important;
  }
  @media (max-width: 440px) {
    div#buttonsContainer {
      display: unset;
    }
    .md-button.headerButtons {
      width: 100%;
      border-radius: 0;
      margin: 0 0 0 0;
    }
  }
  div#descriptionContainer {
    margin: 0 auto;
    width: 90%;
  }
  p.description {
    color: #fff;
    font-size: 2em;
    line-height: 1.2em;
    text-align: center;
  }
  span.dynchanMark {
    color: #f45303;
  }
  /* ------------------------------------------------------ */
  /*                        FEATURES                        */
  /* ------------------------------------------------------ */
  div#features {
    background-color: #448aff;
    height: auto;
  }
  /* ------------------------------------------------------ */
  /*                          PLANS                         */
  /* ------------------------------------------------------ */

  div#plans {
    background-color: #448aff;
    user-select: none;
  }
  div#plans.triggered *:not(.justkidding) {
    filter: blur(5px);
    -moz-filter: blur(5px);
    -webkit-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    transition: filter 2s ease, -moz-filter 2s ease, -webkit-filter 2s ease, -o-filter 2s ease, -ms-filter 2s ease;
  }

  h1#titlePlans {
    margin-top: 50px;
    margin-bottom: 50px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    font-size: 2em;
  }
  @media (min-width: 440px) {
    h1#titlePlans {
      font-size: 3em;
    }
  }
  div#justkidding {
    position: absolute;
    color: #e91e63;
    font-size: 2em;
    text-align: center;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 55%;
    height: 75%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    line-height: 1.5em;

    background-color: #fffa;
    backdrop-filter: blur(5px);
    border-radius: 10px;
    border: 1px solid black;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    left: 22.5%;
    top: 10%;
  }
  @media (min-width: 1101px) {
    div#justkidding {
    }
  }
  @media (max-width: 1100px) {
    div#justkidding {
      position: sticky;

      width: 100%;
      left: 0%;
      top: 0%;
      height: 100vh;
      margin-top: 0px;
      box-shadow: none;
      border-radius: 0px;
      border: none;
    }
  }
  span.justkidding {
    width: 100%;
    display: inline-block;
  }
  span.justkidding:first-of-type {
    margin-top: 150px;
  }
  span#donation {
    color: #000;
    font-size: 0.45em;
  }

  div#planContainer::-webkit-scrollbar {
    width: 0;
  }
  div#planContainer {
    height: 100%;
    overflow-y: scroll;
    width: 100%;
  }
  @media (min-width: 1101px) {
    div#planContainer {
      padding-left: calc(50% - 3 * (175px + 5px));
    }
  }
  @media (max-width: 1100px) {
    @media (min-width: 300px) {
      div#planContainer {
        padding-left: calc(50% - 175px);
      }
    }
  }
  div.plan {
    height: 80%;
    width: 350px;
    max-width: 100%;
    height: 600px;
    background-color: white;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 25px;
    border-radius: 35px;
    padding-top: 50px;
  }
  @media (min-width: 1101px) {
    div.plan {
      border-radius: 0;
    }
    div.plan:first-of-type {
      border-top-left-radius: 35px;
      border-bottom-left-radius: 35px;
    }
    div.plan:last-of-type {
      border-top-right-radius: 35px;
      border-bottom-right-radius: 35px;
    }
  }
  div.plan:last-of-type {
    margin-bottom: 50px;
  }
  @media (min-width: 1101px) {
    div.plan {
      float: left;
      margin: 0 5px 50px 5px;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
    }
  }
  p.planText {
    margin-left: 50px;
    color: #767676;
    font-size: 1.2em;
    font-weight: bold;
  }
  p.planMonthly {
    // margin-top: 60px;
    font-size: 2em;
  }
  p.planPriceCurrency {
    float: left;
    margin-top: -4px;
    margin-right: 5px;
    font-size: 2em;
  }
  p.planPrice {
    color: #212121;
    font-size: 5em;
    font-weight: 700;
    margin-top: 40px;
    margin-left: 5px;
  }
  p.planName {
    margin-top: -20px;
    margin-bottom: 35px;
    font-size: 1.8em;
    font-weight: 600;
  }
  p.otherPayments {
    margin-top: 35px;
    text-decoration: underline;
  }
  p.greyBold {
    font-weight: bold;
  }
  p.noBold,
  span.noBold {
    font-weight: normal;
  }
  span.bold {
    font-weight: bold;
  }
  .md-button.planBuyButton {
    background-color: #03a8f4 !important;
    border-radius: 50px;
    width: 200px;
    height: 50px;
    font-size: 1.4em;
    margin-left: calc(50% - 100px);
    margin-top: 55px;
    font-weight: bold;
    color: white;
  }
  /* ------------------------------------------------------ */
  /*                          FOOTER                         */
  /* ------------------------------------------------------ */
  div#footer {
    background-color: #448aff;
    height: 80px;
    font-weight: bold;
    text-align: center;
    line-height: 50px;
  }
  p#byzlyfer {
    color: white;
  }
</style>
