// jshint esversion: 9
import Vue from "vue";
import VueRouter from "vue-router";

import home from "@/views/home.vue";
import imprint from "@/views/imprint.vue";

Vue.use(VueRouter);
Vue.config.silent = true;

export default new VueRouter({
  mode: "history",
  routes: [
    {
      path: "*",
      components: {
        home,
        imprint,
      },
    },
  ],
});
