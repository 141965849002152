/* eslint-disable no-undef */
// jshint esversion: 9

const credentials = require("@/assets/credentials.json");
const BUILD = credentials.build;

const CONSTANTS = {
  MISC: { build: BUILD != "live" ? BUILD.charAt(0).toUpperCase() + BUILD.slice(1) : "" },
  API: credentials.endpoints[BUILD].api,
  AUTH: {
    LOGOUT: credentials.endpoints[BUILD].auth + `/logout`,
    BACKEND: credentials.endpoints[BUILD].auth + `/auth`,
    DISCORD: `https://discord.com/oauth2/authorize?client_id=${credentials.clientID}&redirect_uri=${credentials.endpoints[BUILD].auth}/return&response_type=code&scope=identify%20guilds`,
  },
  URLS: {
    DISCORD_INVITE: "https://discord.com/invite/hwrb46E/",
    BOT_INVITE:
      "https://discord.com/api/oauth2/authorize?client_id=352801398385016832&permissions=0&redirect_uri=https%3A%2F%2Fdynchan.net&scope=bot",
    TOPGG: "https://top.gg/bot/352801398385016832",
    GITHUB: "https://github.com/dynchan",
  },
  PERMISSIONS: {
    general: [
      {
        name: "Create Instant Invite",
        desc: "Create an instant invite for the voice or text channel",
        bit: 0x0000000001,
      },
      {
        name: "Manage Channels",
        desc: "Manage the settings (name, bitrate, user limit, settings, etc.) for the voice or text channel",
        bit: 0x0000000010,
      },
      // {
      //   name: "Manage Roles",
      //   desc: "Manage Roles",
      //   bit: 0x0010000000,
      // },
      {
        name: "View Channel",
        desc: "View Channel (this includes viewing voice channels and reading messages in text channels)",
        bit: 0x0000000400,
      },
    ],
    voice: [
      // {
      //   name: "Connect",
      //   desc: "Connect",
      //   bit: 0x0000100000,
      // },
      {
        name: "Deafen Members",
        desc: "Deafen other members in the voice channel",
        bit: 0x0000800000,
      },
      {
        name: "Move Members",
        desc: "Move members out of the voice channel",
        bit: 0x0001000000,
      },
      {
        name: "Mute Members",
        desc: "Mute members within the voice channel",
        bit: 0x0000400000,
      },
      {
        name: "Priority Speaker",
        desc: "Become priority speaker",
        bit: 0x0000000100,
      },
      {
        name: "Speak",
        desc: "The ability to speak within the voice channel",
        bit: 0x0000200000,
      },
      {
        name: "Stream & Screen Share",
        desc: "Stream & Screen Share",
        bit: 0x0000000200,
      },
      {
        name: "Start Embedded Activities",
        desc: "Start Embedded Activities",
        bit: 0x8000000000,
        disabled: false,
      },
      {
        name: "Use VAD",
        desc: "Use voice activation detection to avoid the need of push to talk",
        bit: 0x0002000000,
      },
    ],
    text: [
      {
        name: "Add Reactions",
        desc: "Add reactions to messages within the text channel",
        bit: 0x0000000040,
      },
      {
        name: "Attach Files",
        desc: "Attach files in messages within the text channel",
        bit: 0x0000008000,
      },
      {
        name: "Create Private Threads",
        desc: "Create private threads for the text channel",
        bit: 0x1000000000,
        disabled: false,
      },
      {
        name: "Create Public Threads",
        desc: "Create public threads for the text channel",
        bit: 0x0800000000,
        disabled: false,
      },
      {
        name: "Embed Links",
        desc: "Embed links in the text channel",
        bit: 0x0000004000,
      },
      {
        name: "Manage Messages",
        desc: "Manage messages within the text channel (such as deleting messages from other members)",
        bit: 0x0000002000,
      },
      {
        name: "Manage Threads",
        desc: "Manage threads within of the text channel",
        bit: 0x0400000000,
        disabled: false,
      },
      {
        name: "Mention Everyone",
        desc: "Mention @everyone in the text channel",
        bit: 0x0000020000,
      },
      {
        name: "Read Message History",
        desc: "Read messages that were sent before the user was able to see the text channel",
        bit: 0x0000010000,
      },
      {
        name: "Send Messages",
        desc: "Send messages to the text channel",
        bit: 0x0000000800,
      },
      {
        name: "Send Messages in Threads",
        desc: "Send messages in threads within the text channel",
        bit: 0x4000000000,
        disabled: false,
      },
      {
        name: "Send TTS Messages",
        desc: "Send text to speech messages within the text channel",
        bit: 0x0000001000,
      },
      {
        name: "Use Application Commands",
        desc: "Use /commands from bots in the text channel",
        bit: 0x0080000000,
        disabled: false,
      },
      {
        name: "Use External Emojis",
        desc: "Use emojis from other Discord servers within the text channel",
        bit: 0x0000040000,
      },
      {
        name: "Use External Stickers",
        desc: "Use stickers from other Discord servers within the text channel",
        bit: 0x2000000000,
        disabled: false,
      },
    ],
  },
};

module.exports = CONSTANTS;
