// jshint esversion: 9
import Vue from "vue";
import App from "./App.vue";

import VueRouter from "vue-router";
import router from "./router";

import VueMaterial from "vue-material";
import VueCookies from "vue-cookies";

import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

Vue.use(VueRouter);
Vue.use(VueMaterial);
Vue.use(VueCookies);
Vue.component("VueSlider", VueSlider);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
